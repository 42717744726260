import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { Link } from 'gatsby'

import {SanityPost} from '../types'

const ItemsWrap = styled.div`
`

const Wrap = styled.div`

    @media (min-width: 768px) {
        grid-gap: 6.5rem;
        display: grid;
        :nth-child(even) {
            grid-template-columns: 3fr 2fr;
            .text-box {
                grid-column: 1;
            }
            .image-box {
                grid-column: 2;
                grid-row: 1;
            }
            .image {
                clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
                right: 10%;
            }
        }
        :nth-child(odd) {
            grid-template-columns: 2fr 3fr;
            .image {
                left: 10%;
            }
        }

        margin: 3rem 0 4rem;
        padding-bottom: 2rem;
    }

    :hover {
        .image {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }

    @media (max-width: 768px) {
        .image-box {
            width: 50vw;
            margin: 0 auto;
            margin-top: 5rem;
            margin-bottom: 3rem;
        }
        .image {
            left: 10%;
        }
    }
`

const ImageBox = styled.div`
    position: relative;

    .image {
        transition: all 150ms ease-in-out;
        position: absolute;
        top: 2rem;
        clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
    }
`

const TextBox = styled.div`
    p {
        font-family: ${p => p.theme.lora}
    }
    h3 {

    }
`

export interface ItemPreviewProps {
    posts: SanityPost[];
}

const redirectMap = {
  'round-anything-a-pragmatic-approach-to-openscad-design': 'https://learn.cadhub.xyz/docs/round-anything/overview',
  'round-anything-api': 'https://learn.cadhub.xyz/docs/round-anything/api-reference',
  'curated-code-cad': 'https://learn.cadhub.xyz/blog/curated-code-cad',
  'openscad-review': 'https://learn.cadhub.xyz/blog/openscad-review',
}

const makeLink = (slug: string):string => redirectMap[slug] || `/blog/${slug}`

const ItemPreview: React.FC<ItemPreviewProps> = ({posts}) => {
    return (
        <ItemsWrap>
            {posts.filter(({preview}) => !preview).map(post => (
                    <Wrap className="mb-24" key={post.id}>
                        <ImageBox className='image-box bg-gray-300'>
                            <Link to={makeLink(post.slug.current)}>
                                <Image className='image' fluid={post.mainImage.asset.fluid} />
                            </Link>
                        </ImageBox>
                        <TextBox className='text-box'>
                            <Link to={makeLink(post.slug.current)}><h3>
                                {post.title}
                                <div className="w-10 h-1 mt-1 bg-gray-300"/>
                            </h3></Link>
                            <p>{generateExcerpt(post._rawBody)}</p>
                        </TextBox>
                    </Wrap>
            ))}
        </ItemsWrap>
    )
}

function generateExcerpt (rawDescription) {
    let exerpt =  rawDescription
        .reduce((acc, {children}) => `${acc} ${(children || []).map(({text}) => text).join(' ')}`, '')
        .substring(0, 400)
    if (exerpt.length === 400) {
        exerpt += '. . .'
    }
    return exerpt
}

export default ItemPreview
