import React from 'react'
import {graphql} from 'gatsby'
import Image from 'gatsby-image'
import {isFuture} from 'date-fns'
import styled from 'styled-components'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import {HomeIntro} from '../components/HomeIntro'
import {NewsletterPitch} from '../components/NewsletterPitch'
import Layout from '../containers/layout'
import { SanityPost, SanitySiteSettings} from '../types';
import {Edges} from '../helper'
import ItemPreview from '../components/ItemPreview'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
      heroImage {
        asset {
          fluid(maxWidth: 1300, maxHeight: 450) {
              ...GatsbySanityImageFluid
          }
        }
      }
      headshot {
        asset {
          fluid(maxWidth: 250, maxHeight: 250) {
              ...GatsbySanityImageFluid
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 20
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          preview
          publishedAt
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                  ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`

interface Props {
  data: {
    posts: Edges<SanityPost>
    site: SanitySiteSettings;
  }
  errors;
}

const IndexPage:React.FC<Props> = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = data.site
  const postNodes = data.posts.edges.map(edge => edge.node)
    .filter(({publishedAt, slug}) => !isFuture(publishedAt) && slug)
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div>
        <HomeIntro
          headshot={site.headshot.asset}
          heroImage={site.heroImage.asset}
        />
        <div className='max-w-6xl mx-auto'>
          {postNodes &&  <ItemPreview posts={postNodes} />}
        </div>
        <NewsletterPitch />
      </div>
    </Layout>
  )
}

export default IndexPage